import React, { FC } from "react";
import { RichPageHeaderProps } from "./../generated-types";
import { mediaApi, sc, useSitecoreContext } from "~/foundation/Jss";
import { Ribbon } from "~/foundation/Components/Ribbon";
import {
	AspectRatio,
	Box,
	Flex,
	Heading,
	Text as ChakraText
} from "@chakra-ui/react";
import { imageSizes } from "~/foundation/Theme";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { PageHeaderBase, PageHeaderImageBase, PageHeaderLinksBase } from "~/foundation/SitecoreContent/generated-types";
import { useParallax } from "react-scroll-parallax";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";
import { BreakingNews } from "~/foundation/Components/BreakingNews";
import { SiteNavigation } from "~/foundation/Components/SiteNavigation";
import { Helmet } from "react-helmet-async";

const RichPageHeader: FC<RichPageHeaderProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<PageHeaderBase & PageHeaderImageBase & PageHeaderLinksBase & PageThemeBase>()
	const showImage = sitecoreContext.route?.fields?.image?.value?.src || sitecoreContext.pageEditing;
	const showLinkSection = sitecoreContext.route?.fields?.featuredPrimaryLink || sitecoreContext.route?.fields?.featuredSecondaryLink || sitecoreContext.pageEditing;
	const theme = usePageTheme(sitecoreContext);
	const pageHeaderSpacingNone = sitecoreContext.route?.fields?.pageHeaderSpacing?.value === "none";
	const isRtl = sitecoreContext.custom.settings.isRtl;

	let parallax = {
		ref: React.createRef<HTMLDivElement>()
	};

	if (showImage && !sitecoreContext.pageEditing) {
		parallax = useParallax<HTMLDivElement>({
			speed: -15,
			rootMargin: { top: 0, right: 0, bottom: 500, left: 0 }
		});
	}

	return (
		<>
			{showImage &&
				<Helmet>
					<link rel="preload" as="image" href={mediaApi.updateImageUrl(sitecoreContext.route?.fields?.image?.value?.src, imageSizes.none)} imageSrcSet={mediaApi.getSrcSet(sitecoreContext.route?.fields?.image?.value?.src, [imageSizes.richpageHeader.portrait.md, imageSizes.richpageHeader.portrait.lg, imageSizes.richpageHeader.portrait.xl, imageSizes.richpageHeader.landscape.md, imageSizes.richpageHeader.landscape.lg, imageSizes.richpageHeader.landscape.xl])} />
				</Helmet>
			}

			<Ribbon
				pt={["var(--chakra-sizes-headerHeightBase)", null, null, "var(--chakra-sizes-headerHeightLG)"]}
				pb={pageHeaderSpacingNone ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
				bg={theme.colors.ribbon.bg}
				color={theme.colors.ribbon.color}>
				<BreakingNews sitecoreContext={sitecoreContext} />
				<SiteNavigation sitecoreContext={sitecoreContext} />
				<Box pt="8">
					<Box className="breadcrumb--on-top">
						<sc.Placeholder name={rendering.placeholderNames.breadcrumb_navigation} rendering={rendering}/>
					</Box>
					<ContentWrapper position="relative" py="0" px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}>
						{showImage &&
							<Box borderRadius="lg" borderBottomRightRadius="0" transform="translateZ(0)" overflow="hidden">
								<AspectRatio ratio={[2 / 3, null, 16 / 9]} sx={
									sitecoreContext.pageEditing ? {} : {
										'.LazyLoad': {
											height: "100%",
											width: "100%",
										},
										'&:after': {
											content: `""`,
											position: 'absolute',
											top: "0",
											bgColor: 'blackAlpha.400',
											h: '100%',
											w: '100%',
										}
									}}>
									<Box
										sx={
											sitecoreContext.pageEditing ? {} : {
												'&:after': {
													content: `""`,
													position: 'absolute',
													top: "0",
													bg: ["linear-gradient(69deg, rgba(0, 0, 0, 0.76) 31.16%, rgba(0, 0, 0, 0.00) 90.51%)", null, "linear-gradient(86deg, rgba(0, 0, 0, 0.57) 31.08%, rgba(0, 0, 0, 0.00) 71.97%, rgba(22, 19, 19, 0.00) 103.02%), linear-gradient(180deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 27.65%)"],
													h: '100%',
													w: '100%',
													transform: isRtl ? "scaleX(-1)" : "",
												}
											}}
									>
										<Box ref={parallax.ref} height={sitecoreContext.pageEditing ? "100%" : "120% !important"} position="absolute" w="100%">
											<sc.AdvanceImage
												fields={sitecoreContext.route?.fields}
												itemId={sitecoreContext.itemId}
												fieldName="image"
												srcSet={[imageSizes.richpageHeader.portrait.md, imageSizes.richpageHeader.portrait.lg, imageSizes.richpageHeader.portrait.xl, imageSizes.richpageHeader.landscape.md, imageSizes.richpageHeader.landscape.lg, imageSizes.richpageHeader.landscape.xl]}
												sizes="100vw"
												sx={{
													position: "absolute",
													objectFit: "cover",
													objectPosition: "center center",
													minWidth: "100%",
													minHeight: "100%",
												}}
												lazy={false}
											/>
										</Box>
									</Box>
								</AspectRatio>
							</Box>
						}
						<Box position="absolute" top="0" h="100%" pt={["16", null, "32"]} pb={["16", null, "32"]} px={["var(--chakra-sizes-pageMarginSM)", null, "8", null, "16"]} color="white">
							<Flex
								direction="column" h="100%"
								justifyContent="center"
							>
								{(sitecoreContext.route?.fields?.trumpet?.value || sitecoreContext.pageEditing) &&
								<Box maxW={["100%", null, "582"]} mb="4" pe={["4", null, "0"]}>
									{
										(sitecoreContext.route?.fields?.featuredPrimaryLink?.value?.href && !sitecoreContext.pageEditing)
											?
											<sc.Link field={sitecoreContext.route?.fields?.featuredPrimaryLink} showLinkTextWithChildrenPresent={false}>
												<ChakraText variant="overline" display="inline-block">
													<sc.Text field={sitecoreContext.route?.fields?.trumpet} />
												</ChakraText>
											</sc.Link>
											:
											<ChakraText variant="overline">
												<sc.Text field={sitecoreContext.route?.fields?.trumpet} />
											</ChakraText>
									}
								</Box>
								}
								{(sitecoreContext.route?.fields?.headline?.value || sitecoreContext.pageEditing) &&
									<Box maxW={["100%", null, "582"]} pe={["4", null, "0"]}>
										{
											(sitecoreContext.route?.fields?.featuredPrimaryLink?.value?.href && !sitecoreContext.pageEditing)
												?
												<sc.Link field={sitecoreContext.route?.fields?.featuredPrimaryLink} showLinkTextWithChildrenPresent={false}>
													<Heading
														as="h1"
														size="xxl"
														mb={["6", null, "8"]}
														display="inline-block">
														<sc.Text field={sitecoreContext.route?.fields?.headline} />
													</Heading>
												</sc.Link>
												:
												<Heading as="h1" size="xxl" mb={["6", null, "8"]}>
													<sc.Text field={sitecoreContext.route?.fields?.headline} />
												</Heading>
										}
									</Box>
								}
								{(sitecoreContext.route?.fields?.description?.value || sitecoreContext.pageEditing) &&
									<ChakraText
										as="span"
										display="block"
										size="xs"
										mb={["6", null, "8"]}
										maxW={["100%", null, "582"]}
										pe={["4", null, "0"]}
										fontWeight="300"
									>
										<sc.RichText field={sitecoreContext.route?.fields?.description} />
									</ChakraText>
								}
								{showLinkSection &&
									<Flex wrap="wrap" direction={["column", null, "row"]} pe={["4", null, "0"]}>
										<>
											{(sitecoreContext.route?.fields?.featuredPrimaryLink?.value.href || sitecoreContext.pageEditing) &&
												<Box me={[null, null, "16"]}>
													<sc.Link field={sitecoreContext.route?.fields?.featuredPrimaryLink} variant="primary" size="md" color="white" />
												</Box>
											}
											{(sitecoreContext.route?.fields?.featuredSecondaryLink?.value.href || sitecoreContext.pageEditing) &&
												<Box mt={["6", null, "0"]} display="flex">
													<sc.Link field={sitecoreContext.route?.fields?.featuredSecondaryLink} variant="secondary" size="md" color="white"/>
												</Box>
											}
										</>
									</Flex>
								}
							</Flex>
						</Box>
					</ContentWrapper>
				</Box>
			</Ribbon>
		</>
	);
};

export default RichPageHeader;
