import React, { FC } from "react";
import { SitecoreContextValue } from "~/foundation/Jss";
import { Box, Flex, Text as ChakraText } from "@chakra-ui/react";
import { Ribbon } from "../Ribbon";
import { ContentWrapper } from "../ContentWrapper";
import { sizes } from "~/foundation/Theme/variables/sizes";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";
import { ChakraRouterLink } from "../Link/ChakraRouterLink";
import { SiteNavigationSettings } from "~/foundation/Jss/generated-types";

type SiteNavigationProps = {
	sitecoreContext: SitecoreContextValue
};

export const SiteNavigation: FC<SiteNavigationProps> = ({ sitecoreContext }) => {
	const siteNavigation = sitecoreContext.custom.settings.siteNavigation as SiteNavigationSettings | undefined;
	if (!siteNavigation || !siteNavigation.showSiteNavigation || !siteNavigation.globalSiteLinkUrl) {
		return <></>;
	}

	const siteNavigationLink = siteNavigation.globalSiteLinkUrl;
	const hasDynamicHeaderBackground = sitecoreContext.custom.hasDynamicHeaderBackground;
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const theme = usePageTheme(sitecoreContext);

	return (
		<Ribbon
			position={hasDynamicHeaderBackground ? "absolute" : ["relative", null, null, "absolute"]}
			zIndex={["1", null, null, "3"]}
			top={hasDynamicHeaderBackground ? ["var(--chakra-sizes-headerHeightBase)", null, null, "0"] : "0"}
			left="0"
			width="100%"
			pointerEvents="none"
			overflow="hidden">
			<ContentWrapper
				py={["0", null]}
				px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}
				bg={theme.colors.siteNavigation.gradient}
				position="relative"
				sx={{
					"&:after": {
						content: "''",
						display: "block",
						position: "absolute",
						top: "0",
						bottom: "0",
						left: `${isRtl ? "auto" : "calc( 100% )"}`,
						right: `${isRtl ? "calc( 100% - 1px )" : "auto"}`,
						bg: theme.colors.siteNavigation.bg,
						w: ["0", null, `calc((100vw - ${sizes.contentWrapperWidthMD}) / 2 + 1px)`],
					}
				}}>
				<Flex justifyContent="flex-end" noOfLines={1} color={theme.colors.siteNavigation.color}>
					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						h="2.625rem"
						flexBasis={["100%", null, null, "calc(100% - 400px)"]}>
						{siteNavigation.siteTitleText && (
							<>
								<ChakraText variant="footerBody">
									{siteNavigation.siteTitleText}
								</ChakraText>
								<ChakraText variant="footerBody" px="1">-</ChakraText>
							</>
						)}
						<ChakraRouterLink
							isExternal
							to={siteNavigationLink}
							pointerEvents="auto">
							<ChakraText
								variant="footerBody"
								sx={siteNavigationLink ? { borderBottom: "1px solid currentColor" } : {}}>
								{siteNavigation.globalSiteLinkText}
							</ChakraText>
						</ChakraRouterLink>
					</Box>
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};